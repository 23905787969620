<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="info d-none d-md-flex align-center justify-center"
    >
      <SupportArht />
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/img/arhtLogo.png" />
              <h6 class="subtitle-1">ARHT Engine customer portal</h6>
              <h2 class="font-weight-bold mt-4 white--text">Password reset</h2>

              <template v-if="!sended">
                <v-form ref="form" data-vv-scope="form-reset-password">
                  <ul v-if="errors_api" class="error-messages">
                    <li v-for="(v, k) in errors_api" :key="k">{{ v }}</li>
                  </ul>
                  <v-alert
                    :value="true"
                    type="error"
                    v-if="errors.has('form-reset-password.general_error_field')"
                  >
                    {{
                      errors.first("form-reset-password.general_error_field")
                    }}
                  </v-alert>

                  <v-text-field
                    v-model.trim="email"
                    data-vv-name="email"
                    v-validate="'required|emailWithSpaces'"
                    label="E-mail"
                    class="mt-4"
                    required
                    outlined
                    :error-messages="
                      errors.collect('form-reset-password.email')
                    "
                    v-on:keyup.enter="submit"
                    data-testid="email"
                  />

                  <div>
                    <v-btn
                      color="red lighten-2"
                      to="/"
                      class="mr-4 mb-4"
                      data-testid="btn-cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="
                        !(errors.items.length === 0 && email.length > 0)
                      "
                      color="info"
                      class="mr-4 mb-4"
                      submit
                      @click="submit"
                      data-testid="btn-reset-password"
                    >
                      Reset password
                    </v-btn>
                  </div>
                </v-form>
              </template>

              <template v-if="sended && email">
                <v-alert color="success" dark>
                  On {{ email }} was send message with password recovery link.
                  Please folow the instructions to reset password.
                </v-alert>

                <v-btn color="info" to="/" class="mr-4 mt-4" block>
                  Back to login page
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import "@/validators/login/login-validator"
import { mapState } from "vuex"
import GeneralMixin from "@/mixins/general"
import SupportArht from "./SupportArht"

export default {
  components: {
    SupportArht,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      email: "",
      sended: false,
      dictionary: {
        attributes: {
          email: "E-mail Address",
        },
      },
    }
  },

  mounted() {
    this.$validator.localize("en", this.dictionary)
  },

  methods: {
    submit() {
      let self = this
      let email = self.email.toString().trim()
      let data = {
        email: email,
      }
      self.$validator.validateAll("form-reset-password").then((result) => {
        if (result) {
          self.$store
            .dispatch("resetPassword", data)
            .then((result) => {
              self.sended = true
              // app.$router.push({ name: "home" })
            })
            .catch((error) => {
              self.handleValidationErrors(error.response, "form-reset-password")
            })
        }
      })
    },
  },

  computed: {
    ...mapState({
      errors_api: (state) => state.auth.errors,
    }),
  },
}
</script>
