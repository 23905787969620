import axios from "axios"
import { Validator } from "vee-validate"

const isUniqueEmail = (value) => {
  return axios.post("/api/", { email: value }).then((response) => {
    return {
      valid: response.data.valid,
      data: {
        message: response.data.message,
      },
    }
  })
}

Validator.extend("uniqueEmail", {
  validate: isUniqueEmail,
  getMessage: (field, params, data) => {
    return data.message
  },
})
